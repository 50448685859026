import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Page: NextPage = () => {
	const router = useRouter();

	useEffect(() => {
		router.replace('/coming-soon');
	}, [router]);

	return <></>;
};

export default Page;
